import {Observation} from '~/graphql/graphql';

export function useObservation() {
  const isOpen = useState('observation-modal', () => false);
  const currentObservation = useState<Observation | undefined>('current-observation');

  return {
    isOpen,
    open(observation?: Observation | undefined) {
      if (observation) {
        currentObservation.value = observation;
      }

      isOpen.value = true;
    },
    close() {
      currentObservation.value = undefined;
      isOpen.value = false;
    },
    currentObservation,
  };
}
